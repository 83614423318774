<template>
  <span
    v-if="isFPlus || isOnlineFirst"
    class="furche-content-type-badge"
    :class="{
      'furche-content-type-badge--single': isSingle,
      'furche-content-type-badge--combined': isCombined,
    }"
  >
    <span v-if="isFPlus" class="furche-content-type-badge__fplus">
      <FPlus aria-label="Furche Plus - Abopflichtiger Inhalt" role="img" />
    </span>
    <span
      v-if="isOnlineFirst"
      class="furche-content-type-badge__online-first"
      aria-label="Furche - ONLINE FIRST - Inhaklt zuerst online verfügbar"
    >
      ONLINE<br />
      FIRST
    </span>
  </span>
</template>

<script>
import FPlus from '@/components/ui/icons/f-plus.svg'

export default {
  components: {
    FPlus,
  },
  props: {
    fPlus: {
      type: Number,
      required: false,
      default: 0,
    },
    onlineFirst: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    isFPlus() {
      return this.fPlus !== 0
    },
    isOnlineFirst() {
      return this.onlineFirst
    },
    isCombined() {
      return this.fPlus && this.onlineFirst
    },
    isSingle() {
      return (this.fPlus && !this.onlineFirst) || (!this.fPlus && this.onlineFirst)
    },
  },
}
</script>

<style lang="scss">
@use '../../../css/import/vars';

.furche-content-type-badge {
  border-radius: 6px;
  display: flex;
  align-items: stretch;
  justify-content: center;
  box-sizing: border-box;

  svg {
    display: block;
    transform: scale(0.65);
  }

  &__fplus {
    background-color: vars.$color-black;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1px;
    width: 30px;
    height: 22px;
  }

  &__online-first {
    background-color: vars.$color-orange;
    color: vars.$color-white;
    line-height: 100%;
    font-weight: 400;
    font-size: 10px;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 1px;
    width: 45px;
    height: 22px;
  }

  &--single {
    .furche-content-type-badge__fplus,
    .furche-content-type-badge__online-first {
      border-radius: 2px;
    }
  }

  &--combined {
    .furche-content-type-badge__fplus {
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
    }
    .furche-content-type-badge__online-first {
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }
  }
}
</style>

<template>
  <article class="star-authors-teaser" :style="authorBackground">
    <header class="star-authors-teaser__header">
      <i>
        <IconQuote />
      </i>
      <SubHeadline v-if="starAuthor.attributes.quote" tag="h3" class="star-authors-teaser__headline">
        {{ starAuthor.attributes.quote }}
      </SubHeadline>
      <p class="star-authors-teaser__author">
        <AuthorLink :author="starAuthor" class="star-authors-teaser__author-link">
          {{ starAuthor.attributes.firstName }} {{ starAuthor.attributes.lastName }}
        </AuthorLink>
      </p>
    </header>
    <span v-if="starAuthor.attributes.articleCount" class="star-authors-teaser__count">
      {{ starAuthor.attributes.articleCount }}
      <template v-if="starAuthor.attributes.articleCount === 1"> Beitrag </template>
      <template v-else> Beiträge </template>
    </span>
  </article>
</template>

<script>
import SubHeadline from '@/components/ui/text/subheadline'
import AuthorLink from '@/components/ui/author/link'

import IconQuote from '@/components/ui/icons/icon-quote.svg'

import imageUrls from '@/mixins/imageUrls'

export default {
  components: {
    SubHeadline,
    IconQuote,
    AuthorLink,
  },
  mixins: [imageUrls],
  props: {
    starAuthor: {
      type: Object,
      required: true,
    },
  },
  computed: {
    authorSlug() {
      return this.$slugify(`${this.starAuthor.attributes.firstName} ${this.starAuthor.attributes.lastName}`)
    },
    authorBackground() {
      if (this.starAuthor.attributes.teaserImage) {
        const fileName = encodeURIComponent(this.starAuthor.attributes.teaserImage.attributes.fileName)
        const background =
          this.generateImageUrl(this.starAuthor.id) +
          '/' +
          this.starAuthor.attributes.teaserImage.attributes.fileId +
          '-252x410c-' +
          fileName
        return `background-image: url('${background}`
      }
      return ''
    },
  },
}
</script>

<style lang="scss">
@use '../../../css/import/vars';

.star-authors-teaser {
  width: 100%;
  background-color: #1c1c1c;
  min-height: 380px;
  height: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 126px 205px;
  overflow: hidden;
  position: relative;

  &:hover {
    .star-authors-teaser__count {
      transform: scale(1.05);
    }
  }

  &__header {
    width: 100%;
  }

  &__headline {
    color: vars.$color-white;
    font-family: vars.$georgia;
    letter-spacing: -0.51px;
    font-size: 24px;
    line-height: 32px;
    font-weight: 200;
    font-variation-settings: 'wght' 200;
    margin: 10px 0 10px 0;
  }

  &__author {
    color: vars.$color-white;
    font-size: 13px;
    line-height: 17px;
    font-weight: 600;
    font-variation-settings: 'wght' 600;
    margin-bottom: 30px;

    &-link {
      color: vars.$color-white;

      &:hover,
      &:focus,
      &:active {
        color: vars.$color-white;
        text-decoration: none;
      }

      &:after {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }

  &__count {
    border: 1px solid vars.$color-white;
    height: 30px;
    border-radius: 30px;
    margin-bottom: 1rem;
    color: vars.$color-white;
    text-align: center;
    padding: 0 25px;
    font-size: 15px;
    font-weight: 600;
    font-variation-settings: 'wght' 600;
    transition-duration: 0.3s;
  }
}
</style>
